import React from "react"
import Logo from "../components/common/logo/Logo"
import Footer from "../components/footer/footer"
import { graphql } from "gatsby"
import ExtendedContent from "../components/extendedContent"
import ChangeLanguage from "../components/changeLanguage/ChangeLanguage"
import CookiesBar from "../components/cookiesBar/CookiesBar"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {

	const activeSite = pageContext.site
	const { entries, logo, socialMedia, modals, translations, footer, pojedyncze, produkty, aktualnosci, aktualnosciWszystkie, cookies, seoPojedyncze, seoProdukty, seoAktualnosci, seoDefault } = data.craft
	const entry = pojedyncze.length > 0 ? pojedyncze[0].wybierzModul : produkty.length > 0 ? produkty[0].wybierzModul : aktualnosci[0]
	const translationsData = (translations && translations[0]) || []
	const logoData = (logo && logo[0]) || []
	const footerData = (footer && footer[0]) || []
	const socialMediaData = (socialMedia && socialMedia[0]) || []
	const modalData = (modals && modals[0]) || []
	const seoData = seoPojedyncze.length > 0 ? seoPojedyncze[0] : seoProdukty.length > 0 ? seoProdukty[0] : seoAktualnosci.length > 0 ? seoAktualnosci[0] : ""
	const seoDefaultData = (seoDefault && seoDefault[0])|| ""


	return (
		<>
			<SEO seoData={seoData} seoDefault={seoDefaultData} pageContext={pageContext}/>
			<Logo logoData={logoData} activeSite={activeSite}/>
			<ChangeLanguage firstOtherLangPathArray={entries} pageContext={pageContext}/>
			<ExtendedContent data={entry} pageContext={pageContext} modalData={modalData} translationsData={translationsData}
											 activeSite={activeSite} newsAll={aktualnosciWszystkie}/>
			<CookiesBar cookies={cookies} activeSite={activeSite}/>
			<Footer footerData={footerData} socialMediaData={socialMediaData} activeSite={activeSite}/>
		</>
	)
}

export default IndexPage

export const query = graphql`

    query($id: [Craft_QueryArgument], $site: [String], $firstOtherLangSite: [String]){
        craft {
						seoDefault:  globalSets(site: $site, handle: "seo") {
								...seoDefault
						}
            seoPojedyncze: entries(section:  "pojedyncze", id: $id, site: $site) {
                ...seoPojedyncze
            }
            seoProdukty: entries(section: "produkty", id: $id, site: $site) {
                ...seoProdukty
            }
            seoAktualnosci: entries(section: "aktualnosci", id: $id, site: $site) {
                ...seoAktualnosci
            }
            menu: entries(section: "menu", site: $site) {
                ...menu
            }
            entries(section:  ["pojedyncze","produkty","aktualnosci"], site: $firstOtherLangSite) {
                slug
                id
            }
            pojedyncze: entries(section: "pojedyncze", id: $id, site: $site) {
                ... on Craft_pojedyncze_pojedyncze_Entry {
                    wybierzModul {
                        __typename
                        ...slider,
                        ...naglowekOpisIPrzycisk,
                        ...naglowekIOpis,
                        ...wizytowkaAktualnosci,
                        ...wizytowkaProdukt,
                        ...chceszWiedziecWiecej,
                        ...kontaktModulKontaktowy,
                        ...banerDlaONas,
                        ...zarzad,
                        ...karieraWString,
                        ...ofertyPracy,
                        ...banerZTekstemIZdjeciem,
                        ...zobaczRowniez,
                        ...trescPodstrony,
                        ...banerZNadchodzacaAktualnoscia,
                        ...banerTekstowy,
                        ...string404
                    }
                }
            }
            produkty: entries(section: "produkty", id: $id, site: $site) {
                ... on Craft_produkty_produkty_Entry {
                    wybierzModul {
                        __typename
                        ...produktPiktogram,
                        ...produktZaufalyNam,
                        ...produktPrezentacja3d,
                        ...produktBaner,
                        ...zobaczRowniez,
                        ...produktSpecyfikacja,
                        ...produktInterface,
                        ...produktGaleria,
                        ...produktOpakowanie,
                        ...produktJestesZainteresowanyKupnem,
                        ...produktKupno,
                        ...produktInfografika
                    }
                }
            }
            aktualnosci: entries(section: "aktualnosci", id: $id, site: $site) {
                ...aktualnosciTresci,
                ...zobaczRowniez
            }
            aktualnosciWszystkie: entries(section: "aktualnosci", site: $site) {
                ...aktualnosci
            }
            logo: globalSets(site: $site, handle: "logo") {
                ...logo
            }
            translations: globalSets(site: $site, handle: "tlumaczenie") {
                ...tlumaczenia
            }
            socialMedia: globalSets(site: $site, handle: "socialMedia") {
                ...socialMedia
            }
            modals: globalSets(site: $site, handle: "modale") {
                ...modale
            }
            footer: globalSets(site: $site, handle: "stopka") {
                ...stopka
            }
						cookies: globalSets(site: $site, handle: "cookies") {
              	...cookies
            }
        }
    }

`
